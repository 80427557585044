<template>
  <v-container fluid class="ma-0 pa-0 align-self-start">
    <v-row class="ma-0 pa-0">
      <v-col cols="12" class="ma-0 pa-0">
        <v-card flat tile width="100%">
          <v-card-text class="pa-0">
            <v-row class="ma-0 pa-0">
              <v-col cols="12" md="7" class="pt-0 pl-0">
                <v-card :loading="loading"
                        :max-height="$vuetify.breakpoint.height / 1.3"
                        :min-height="$vuetify.breakpoint.height / 1.5">
                  <v-card-title class="pb-0">
                    <p>Statistik nach Berichtstyp</p>
                  </v-card-title>
                  <v-card-subtitle>
                    <p>Klicken Sie auf ein Segment, um nähere Informationen zu erhalten</p>
                  </v-card-subtitle>
                  <v-card-subtitle>
                    <strong class="text-h6">Total:&nbsp;</strong>
                    <strong class="text-h6 font-weight-bold">{{ statistic['total'] }}</strong>
                  </v-card-subtitle>
                  <v-card-text :style="`min-height: ${$vuetify.breakpoint.height / 2}px`"
                               id="byStatus" class="fill-height"></v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="5" class="pt-0 pl-0">
                <v-card :loading="loading" :min-height="$vuetify.breakpoint.height / 2">
                  <v-card-title>
                    <p>Statistik über die Anzahl der Firmenberichte</p>
                  </v-card-title>
                  <v-card-text :style="`min-height: ${$vuetify.breakpoint.height / 2}px`"
                               id="byOrganisation" class="fill-height"></v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable no-case-declarations,no-unused-vars,no-unreachable */
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
export default {
  name: "Statistic",
  data () {
    return {
      statistic: null,
      selected: undefined,
      loading: true
    }
  },
  mounted() {
    this.fetchStatistic()
  },
  methods: {
    buildChart (id, type) {
      am4core.useTheme(am4themes_animated)

      let chart = am4core.create(id, am4charts[type])
      let data = []

      switch (type) {
        case 'XYChart':
          let labels = this.statistic['organisations'].map(({organisation}) => organisation)

          labels.forEach((label) => {
            data.push({company: label, count: Math.ceil(this.statistic.statistic['byOrganisation'][label].count)})
          })
          let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
          categoryAxis.renderer.grid.template.location = 0;
          categoryAxis.dataFields.category = "company";
          categoryAxis.renderer.minGridDistance = 1;
          categoryAxis.renderer.inversed = true;
          categoryAxis.renderer.grid.template.disabled = true;

          let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
          valueAxis.min = 0;
          valueAxis.adjustLabelPrecision = false;

          let series = chart.series.push(new am4charts.ColumnSeries());
          series.dataFields.categoryY = "company";
          series.dataFields.valueX = "count";
          series.tooltipText = "{valueX.value}"
          series.columns.template.strokeOpacity = 0;

          let labelBullet = series.bullets.push(new am4charts.LabelBullet())
          labelBullet.label.horizontalCenter = "left";
          labelBullet.label.dx = 10;
          labelBullet.label.text = "{values.valueX.workingValue.formatNumber('#.0as')}";
          labelBullet.locationX = 1;

          series.columns.template.adapter.add("fill", function(fill, target){
            return chart.colors.getIndex(target.dataItem.index);
          });

          categoryAxis.sortBySeries = series;

          chart.data = data

          break
        case 'PieChart':

          chart.data = this.generateChartData()
          chart.legend = new am4charts.Legend();
          let pieSeries = chart.series.push(new am4charts.PieSeries());
          pieSeries.dataFields.value = "value";
          pieSeries.dataFields.category = "type";
          pieSeries.slices.template.propertyFields.fill = "color";
          pieSeries.slices.template.propertyFields.isActive = "pulled";
          pieSeries.slices.template.strokeWidth = 0;

          pieSeries.slices.template.events.on("hit", (event) => {
            if (event.target.dataItem.dataContext.id !== undefined) {
              this.selected = event.target.dataItem.dataContext.id;
            } else {
              this.selected = undefined;
            }
            chart.data = this.generateChartData()
          })

          break

        default:
          break
      }
    },
    fetchStatistic () {
      this.$http.get('/auth/statistics')
          .then((response) => {
            this.statistic = response.data
            setTimeout(() => {
              this.buildChart('byOrganisation', 'XYChart')
              this.buildChart('byStatus', 'PieChart')
              this.loading = false
            }, 1500)
          })
    },
    generateChartData() {
      let data = this.statistic.statistic['byStatus']
      let chartData = [];
      const keys = Object.keys(data);
      let object = {}
      keys.forEach((key, index) => {
        if (index === this.index) {
          Object.keys(data[key].subs).forEach((sub) => {
            object = {
              type: sub,
              value: data[key].subs[sub],
              color: data[key].color,
              pulled: true
            }
            chartData.push(object)
          })
        } else {
          object = {
            type: this.realType(key),
            value: data[key].count,
            color: data[key].color,
            id: index
          }
          chartData.push(object)
        }
      })
      return chartData
    },
    realType(type) {
      switch (type) {
        case 'green':
          return 'gut erfüllt'
        case 'yellow':
          return 'verbesserungsfähig'
        case 'red':
          return 'nicht erfüllt'
      }
      return null
    }
  },
  computed: {
    index () {
      return this.selected
    }
  }
}
</script>

<style scoped>
</style>
